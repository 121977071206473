<template>
	<div class="page">
		<div class="view">
			<div class="viewT">
				<div style="font-weight: bold;">领取记录</div>
				<el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
			</div>
			<!-- 搜索 -->
			<div class="searchView">
				<el-input v-model="keyword" placeholder="请输入关键字" prefix-icon="iconfont el-icon-search"
					style="width: 200px;margin: 0 10px 0 20px;" @keyup.enter.native="handleSearchClick"></el-input>
				<el-button type="primary" style="margin-right: 20px;" @click="handleSearchClick">查询</el-button>
				<div class="search">
					<div class="searchTitle">使用状态:</div>
					<el-select v-model="useStatus" placeholder="请选择" style="width: 143px;" @change="handleSearchClick">
						<el-option v-for="item in useStatusList" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="search">
					<div class="searchTitle">领券日期:</div>
					<el-date-picker v-model="getDate" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" style="width: 230px;" @change="handleSearchClick">
					</el-date-picker>
				</div>
				<div class="search">
					<div class="searchTitle">使用日期:</div>
					<el-date-picker v-model="useDate" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" style="width: 230px;" @change="handleSearchClick">
					</el-date-picker>
				</div>
				<el-button @click="handleResetClick">重置</el-button>
			</div>
			<!-- 表格 -->
			<div class="viewB">
				<div class="tableView">
					<el-table :data="tableData" style="width: 100%">
						<el-table-column prop="no" label="编号">
						</el-table-column>
						<el-table-column prop="memberName" label="用户名">
						</el-table-column>
						<el-table-column prop="memberMobile" label="手机号">
						</el-table-column>
						<el-table-column prop="useStatus.title" label="使用状态">
						</el-table-column>
						<el-table-column label="领券日期">
							<template slot-scope="scope">
								{{$public.FTime(scope.row.receiveTime,'YYYY-MM-DD')}}
							</template>
						</el-table-column>
						<el-table-column prop="barCode" label="使用日期">
							<template slot-scope="scope">
								<span v-if="scope.row.useTime == null">--</span>
								<span v-else>{{$public.FTime(scope.row.useTime,'YYYY-MM-DD')}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="couponName" label="券名称">
						</el-table-column>
						<el-table-column prop="barCode" label="规则">
							<template slot-scope="scope">
								<span v-if="scope.row.ruleInfo">{{scope.row.ruleInfo.ruleName}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="reduceMoney" label="抵扣金额">
						</el-table-column>
					</el-table>
				</div>
				<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
			</div>
		</div>
	</div>
</template>

<script>
	import page from "../../../components/page.vue"
	export default{
		components:{
			page
		},
		data(){
			return{
				page:{   //分页
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				msg:JSON.parse(this.$route.query.msg),
				keyword:'',  //关键字
				useStatus:-1,  //-1全部 0不可用 1未使用 2已使用 3已过期
				useStatusList:[{label:'全部',value:-1},{label:'不可用',value:0},{label:'未使用',value:1},{label:'已使用',value:2},{label:'已过期',value:3},],
				getDate:[],  //领券日期
				useDate:[],  //使用日期
				tableData:[],  //表格
			}
		},
		mounted() {
			this.getTableData()
		},
		methods:{
			handleSearchClick(){
				if(this.getDate == null){
					this.getDate = [];
				}
				if(this.useDate == null){
					this.useDate = [];
				}
				this.page.page = 1;
				this.getTableData()
			},
			//获取表格
			getTableData(){
				let params = {
					pageIndex:this.page.pageIndex,
					pageSize:this.page.pageSize,
					couponId:this.msg.virtualId,
					useStatus:this.useStatus,
					keyword:this.keyword
				}
				if(this.getDate.length == 2){
					params.receiveStartTime = new Date(this.getDate[0]).getTime() / 1000;
					params.receiveEndTime = new Date(this.getDate[1]).getTime() / 1000;
				}
				if(this.useDate.length == 2){
					params.useStartTime = new Date(this.useDate[0]).getTime() / 1000;
					params.useEndTime = new Date(this.useDate[1]).getTime() / 1000;
				}
				this.$http.post("/customer/MemberCoupon/List", params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			},
			//重置
			handleResetClick(){
				this.page.pageIndex = 1;
				this.keyword = '';
				this.useStatus = -1;
				this.getDate = [];
				this.useDate = [];
				this.getTableData();
			},
			//切换分页
			changePage(num){
				this.page.pageIndex = num
				this.getTableData();
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			//返回
			handleBackClick() {
				this.$confirm('是否返回上一页?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		width: 100%;
		height: 100%;
		color: $fontColor;
		.view{
			background: #fff;
			height: 100%;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			.viewT {
				box-sizing: border-box;
				height: 68px;
				display: flex;
				align-items: center;
				border-bottom: solid 1px #F2F2F2;
				padding: 0 20px;
				justify-content: space-between;
			}
			.searchView{
				height: 80px;
				display: flex;
				align-items: center;
				.search {
					display: flex;
					align-items: center;
					margin-right: 10px;
				
					.searchTitle {
						font-size: 14px;
						color: $fontColor;
					}
				}
			}
			.viewB{
				flex: 1;
				background: #fff;
				border-radius: 20px;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				.tableView{
					flex: 1;
					overflow-y: scroll;
				}
				.tableView::-webkit-scrollbar {
					display: none;
				}
			}
		}
	}
</style>
